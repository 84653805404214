import { axisLablesFontSizeSet } from "../../../config/chartCommon";
import eventFire from "../../../utilities/eventFire-inside-highcharts";
import { barColorCode } from "./common";



function IncomersAndGeneratosConfig({
    chartApiData,
    graphFilter,

    userAccessibility,
    daySummaryDrilldownDate,
    daySummaryDrilldownEnabled,
    setDaySummaryDrilldownDate
  }) {


    const seriesData = chartApiData?.sources?.map((source) =>{ 
      
      return({
        name: source.name,
        data: source.data,
        color:source?.color?source?.color:null,
        total:source?.total,
    })});
    const findDuration=(data)=>{
      const durationTime=data?.options?.hour_range||data?.options?.week_range || data?.options?.month_range||''
      return (durationTime!==''?`,&nbsp${durationTime}`:'')
    }
    const findCarbon=(data,index)=>{
      const carbon=data[index]?.point?.co2_emission||''
      return (carbon!==''?`, <b>${carbon?.toFixed(2)}</b> kg CO₂`:'')
    }
    const TooltipNameFormatter=(props)=>{
if(props?.options?.hour_range){
  let name=props.name
  let remove_after = name.indexOf(',');
  let date_alone = name.substring(0, remove_after);
return date_alone
}
else{
  return props?.name
}
    }

    const ColorIndicator=(name)=>{
      return (barColorCode?.filter((colors) => { if (colors?.name === name) return colors?.color })?.map((colors) => (colors.color))?.[0])
    }

  const config = {

    subtitle: {
        text: undefined
      },
  
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        // floating: true,
        y: 30,
        itemStyle: {
          fontWeight: 'lighter',
          fontSize: axisLablesFontSizeSet(userAccessibility)
        },
        labelFormatter:function() {
          if(this?.userOptions?.total!==null){
            return this.name + `${this?.userOptions?.total !==undefined ? ("<b>: &nbsp"+ this?.userOptions?.total?.toFixed(0) + " kWh</b>"):''}`;
          }
          else{
            return this.name
          }
        },
        // reversed: true
      },
      chart: {
        type: 'column',
        className: 'totalenergyuse-incomers-generators',
        spacingBottom: 25
      },



      plotOptions: {
        column: {
            pointPadding: -0.3,
            groupPadding: 0.3,
             /* pointWidth: 10 */
             point: {
              events: {
                click: function (data) {
                  if (daySummaryDrilldownEnabled && data.point.summary_date ) {
                    setDaySummaryDrilldownDate(data.point.summary_date);
                  }
                }
              }
            }
        }
    },
    xAxis: {
        reversedStacks: false,
        lineColor: '#cccccc',
        categories: null,
        labels: {
          autoRotation: undefined,
          formatter: function () {

            if (this.chart.options.chart.className == 'totalenergyuse-incomers-generators') {
  
              return chartApiData && chartApiData?.sources[0]?.data[this.value]?.name;
            }
          },
          style: {
            fontSize: axisLablesFontSizeSet(userAccessibility),
            cursor:
              (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
              daySummaryDrilldownEnabled &&
              !daySummaryDrilldownDate
                ? 'pointer'
                : 'initial'
          },
          padding: 20,
          step: graphFilter.filterMain == '1day' || daySummaryDrilldownDate ? 2 : 0,
          events: {
            click: function () {
              var xaxisLableElement = document.querySelectorAll(
                '.totalenergyuse-main .highcharts-point'
              )[this.pos];
              if (
                (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
                daySummaryDrilldownEnabled
              ) {
                eventFire(xaxisLableElement, 'click');
              }
            }
          }
        },
        tickLength: 0,
        tickPixelInterval: 70
      },
       tooltip: {
        useHTML: true,
        formatter: function() {
          let is_incomplete_flag=false
            let tooltip = '<div class="txt-10">' + 
             TooltipNameFormatter(this.point) + (findDuration(this.point)) +'</div>';
            this.points.forEach((point,index) => {
              if(this.point.incomplete_flag===true){is_incomplete_flag=true}
                tooltip += '<span ><span class="color-indicator" style="backgroundColor:' 
                + `${ColorIndicator( point.series.name)}` + '" ></span>' +point.series.name +
                 '&nbsp('+point.point.options.number_of_equipments+
                 ' equipment) </span> &nbsp<b><br/> &nbsp &nbsp &nbsp &nbsp' 
                 + point.y?.toFixed(2) + '</b> kWh'+findCarbon(this.points,index)+'<br/>';
                // tooltip += 'Number of Equipments: ' + point.point.options.number_of_equipments + '<br/>';
            });
            return (is_incomplete_flag?tooltip+= '<div><span class="color-indicator" style="backgroundColor:' + `${ColorIndicator( 'Incomplete')}` + '" ></span>Incomplete period </div>':tooltip)
            // return tooltip;
        },
        shared: true
    },

      series: seriesData||[],
  
    };
  return config;
}
export default IncomersAndGeneratosConfig;







