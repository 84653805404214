// /* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useRef, useContext, useEffect } from 'react';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import { context as ProcessContext } from '../../../context/process.context';
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from 'react-router-dom';
import MainTotalEnergyUseComponent from '../../../common/layout/components/main.total.energy.use.component';
import KpiMachineLayoutComponent from '../../../common/layout/components/kpi.machine.layout.component';
import ChangePageViewComponent from '../../../common/layout/components/change.page.view.component';
import ReactGA from 'react-ga4';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import MachineStatusDetailContainer from '../../machinestatus/containers/machine.status.detail.container';
import { useDispatch, useSelector } from 'react-redux';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusEnabled } from '../../../redux/company/company.action';
import { getLayerId, getLayerType } from '../../../common/functions/commonFunctions';
function ComponentDetailComponent() {
  const energyRef = useRef();
  const instantRef = useRef();
  let [searchParams] = useSearchParams();

  const process_type = searchParams.get('process_type');
  const process_id = searchParams.get('process_id');
  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
  const sub_process_id = searchParams.get('sub_process_id');
  const equipment_id = searchParams.get('equipment_id');
  const component_id = searchParams.get('component_id');

  // const is_zone_enabled = searchParams.get('is_zone_enabled');
  const kpi_view = searchParams.get('kpi');
  const machine_status_view = searchParams.get('machine_status');
  // const energy_view = searchParams.get('energy');
  // const { process, machine,Equipment } = useParams();
  const { process, machine } = useParams();
  const isMachineStatusEnabled = useSelector((state) => state.company.is_machine_status_enabled);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
//   const handleBackButtonClick = (event) => {
//    event.preventDefault();  // Prevent the default behavior of the Link
//    navigate(-1);  // Navigate back to the previous page
//  };

  useEffect(()=>{
   if(flexibleEquipmentEnabled){
     setPayload({
       auth: true,
       url: 'v0/layers/parent-companies/basic',
       params:{
         layer_type:'plant',
         plant_code:plant_code
       }
     });
   }
 },[plant_code])
 
 useEffect(()=>{
  if(flexibleEquipmentEnabled){
    if (data?.data?.data?.company_info?. kpi_view == 'true') {
      pageViewObject = {
        energy: false,
        kpi: true
      };
    } else if (data?.data?.data?.company_info?. is_machine_status_enabled == true) {
    dispatch(machineStatusEnabled(data?.data?.data?.company_info?. is_machine_status_enabled));
      pageViewObject = {
        energy: false,
        machine_status: true,
        kpi: false
      };
    } else {
      pageViewObject = {
        energy: true,
        kpi: false,
      };
    }
  }
 
},[data])
let prevProcess= useContext(ProcessContext).state.sidebarLinks?.layers?.filter((plants)=>
  plants.layer_id==plant_id
)[0].layers?.filter((process)=>process.layer_id==process_id)[0].layers?.filter((equipments)=>equipments.layer_id==equipment_id)[0]
// console.log('layers......',prevProcess?.name)
  let processList = useContext(ProcessContext).state.sidebarLinks?.layers?.filter((plants)=>
      plants.layer_id==plant_id
    )[0].layers?.filter((process)=>process.layer_id==process_id)[0].layers?.filter((equipments)=>equipments.layer_id==equipment_id)[0];
  let pageViewObject;
  if (kpi_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: false,
      kpi: true
    };
  } else if (machine_status_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  } else {
    pageViewObject = {
      energy: true,
      machine_status: false,
      kpi: false
    };
  }

  const [pageView, setPageView] = useState(pageViewObject);

  // const getEquipementArrayFromZone = (process) => {
  //   let tempProcessList = [];
  //   Object.keys(process.ugn_zones).map((eachZone) => {
  //     let zoneEquipmentList = process.ugn_zones[eachZone].equipments.map((equipment) => ({
  //       process: process.name,
  //       process_id: process.process_id,
        
  //       process_type: process.process_type,
  //       sub_process_id: process.sub_process_id,
  //       equipment_name: equipment.equipment_name,
  //       equipment_id: equipment.equipment_id,
  //       is_zone_enabled: true
  //     }));
  //     tempProcessList = [...tempProcessList, ...zoneEquipmentList];
  //   });
  //   processList = tempProcessList;
  // };
  let processLists =processList?.layers
  // let chartWindows;
  console.log('processList',processList)
  processLists = processLists?.map((process) => ({
    process: process.name,
    process_id: process.layer_id,
    process_type: process.layer_type,
    sub_process_id: process.sub_process_id,
    is_zone_enabled: process.ugn_zones ? true : false
  }));
  // processList?.layers?.map((process) => {
  //   if (process_id == process.process_id && sub_process_id == process.sub_process_id) {
  //     process.equipments
  //       ? (processList = process.equipments.map((equipment) => ({
  //           process: process.name,
  //           process_id: process.process_id,
  //           process_type: process.process_type,
  //           sub_process_id: process.sub_process_id,
  //           equipment_name: equipment.equipment_name,
  //           equipment_id: equipment.equipment_id
  //         })))
  //       : process.ugn_zones
  //       ? getEquipementArrayFromZone(process)
  //       : (processList = []);
  //   }
  // });

  const chartWindows = [
    {
      ref: energyRef,
      component: () => {
        return [
          MainTotalEnergyUseComponent,
          {
            drilldownlevel: 'ComponentLayer',
            process_type: process_type,
            process_id: process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            sub_process_id: sub_process_id,
            equipment_id: equipment_id,
            component_id:component_id,
            layer_id:component_id,
            graph_name: 'Total energy use (kWh)',
            graph_tooltip: 'The amount of power consumed by this equipment.'
          }
        ];
      },
      id: 'energyMachine'
    },
    //{ ref: processRef, component:  ()=>{ return [MainProcessEnergyUseComponent,{drilldownlevel:"Machine",process_type:process_type,process_id:process_id,sub_process_id:sub_process_id, equipment_id:equipment_id, graph_name: 'Process energy use (kWh)'}]}, id: "processProcess"},
    // {
    //   ref: peakRef,
    //   component: () => {
    //     return [
    //       MainInstantaneousPowerComponent,
    //       {
    //         drilldownlevel: 'EquipmentLayer',
    //         process_type: process_type,
    //         process_id: process_id,
    //         sub_process_id: sub_process_id,
    //         equipment_id: equipment_id,
    //         graph_name: 'Instantaneous Power (kW)'
    //       }
    //     ];
    //   },
    //   id: 'peakMachine'
    // }
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            drilldownlevel: 'ComponentLayer',
            process_type: process_type,
            process_id: process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            sub_process_id: sub_process_id,
            equipment_id: equipment_id,
            component_id:component_id,
            graph_name: 'Equipment Instantaneous Power (kW)'
          }
        ];
      },
      id: 'instantaneousMachine'
    }
  ];

  // useEffect(() => {
  //  const path =  "/processes/" + processList[currentProcessIndex]
  //  navigate(path);
  // }, [currentProcessIndex])
  // let params = new URLSearchParams(document.location.search);
  // let energy = params.get("energy");
  // let machineStatus = params.get("machine_status");
  // let kpi = params.get("kpi");
  return (
    <>
      <div className="flex-btw mb-4 ">
        <h6 className="inner-breadcrumb w-25">
          <Link
            // to="#" onClick={handleBackButtonClick}
            to={`/layer_equipment/${encodeURIComponent(prevProcess?.name)}/?process_type=equipment&plant_id=${plant_id}&plant_code=${plant_code}&process_id=${process_id}&equipment_id=${equipment_id}`
            }
          >
            {' '}
            <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>
            {prevProcess?.name}
          </Link>
        </h6>
        <h4 className="text-center font-light">{decodeURIComponent(process)}</h4>

        <div className="flex-c  w-25 justify-content-end">
          <div className="process-nav-dropdown me-3">
            {processLists?.length > 0 ? (
              <Dropdown className="bt-dropdown-nav ">
                <Dropdown.Toggle size="sm" variant="secondary">
                  components 
                </Dropdown.Toggle>

                <Dropdown.Menu className="bt-dropdown-menu">
                  {processLists?.map((process) => {
                    return (
                      <Dropdown.Item
                        key={process.process_id }
                      >
                        <NavLink
                          end
                          activeClassName={'bt-dropdown--active'}
                          to={`/${process.process_type}/${encodeURIComponent(process?.process)}?process_type=${process.layer_type}&process_id=${process_id}&plant_code=${plant_code}&plant_id=${plant_id}&equipment_id=${equipment_id}&component_id=${process?.process_id}`
                          }
                          onClick={ReactGA.event({
                            category: 'Equipment dropdown click',
                            action: 'Selected equipment from dropdown',
                            label: `Equipment ${encodeURIComponent(
                              process.equipment_name
                            )} selected`
                          })}
                        >
                          {process.process}
                        </NavLink>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ''
            )}
          </div>
          <ChangePageViewComponent pageView={pageView} setPageView={setPageView} />
        </div>
      </div>

      {pageView.energy ? (
        <MainLayoutComponent chartWindows={chartWindows} process={decodeURIComponent(machine)} />
      ) : pageView.machine_status && isMachineStatusEnabled ? (
        <MachineStatusDetailContainer
          processDetails={{
            drilldownlevel: 'ComponentLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            equipment_id: equipment_id,
            component_id:component_id,
            plant_code:plant_code,
            layer_id:getLayerId({
              process_id:process_id,
              equipment_id:equipment_id,
              component_id:component_id,
            }),
          layer_type:getLayerType({
            process_id:process_id,
            equipment_id:equipment_id,
            component_id:component_id,
          })


          }}
        />
      ) : (
        <KpiMachineLayoutComponent
          processDetails={{
            drilldownlevel: 'ComponentLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            equipment_id: equipment_id,
            component_id:component_id,
            plant_id:plant_id,
            plant_code:plant_code,
          }}
        />
      )}
    </>
  );
}

export default React.memo(ComponentDetailComponent);
