import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import widgetApiParams from '../../../utilities/widget.api.params';
import totalEnergyUseChartDataConfig from '../../chartdataconfig/mainLayoutConfig/main.total.energy.use.chart.config';
import comparisonTotalEnergyUseConfig from '../../chartdataconfig/comparisonViewConfig/comparison.total.energy.use.config';
import { useLocation, useSearchParams } from 'react-router-dom';
import energyPriceTotalEnergyUseConfig from '../../chartdataconfig/energy.price.total.energy.use.config';
import IncomersAndGeneratosConfig from '../../chartdataconfig/incomersAndGeneratorsConfig/incomers.generators.config';
import { barColorCode } from '../../chartdataconfig/incomersAndGeneratorsConfig/common';
export default function MainTotalEnergyUseContainer({
  INITIALFILTER,
  data,
  config,
  setApiParams,
  props,
  graphFilter,
  graphApiCall,
  initialFilter,
  setInitialFilter,
  filterByDateCheck,
  charRef,
  chartApiData,
  chartApiData2,
  comparisonViewCheck,
  setComparisonViewCheck,
  setConfig,
  apiDateRange,
  comparisonFilter,
  apiParams,
  setChartApiData,
  setChartApiData2,
  setGraphFilter,
  userAccessibility,
  daySummaryDrilldownDate,
  setDaySummaryDrilldownDate,
  daySummaryDrilldownEnabled,
  togglePriceCheck,
  setTogglePriceCheck,
  setChartApiData3,
  chartApiData3,
  incomersValeue,
  generatorsValue,
  chartApiDataIncomersAndGenerator,
  setChartApiDataIncomersAndGenerator,
  setIncomersValue,
  setGeneratorsValue,
  setTotalEnergyUse,
  setTotalEnergyUseVisible = () => { },
  drilldown,
  // setDrilldown,
}) {
  const onSidbarClick = useSelector((state) => state.sidebarClick.linkClick);
  const [horScroll, setHorScroll] = useState(undefined);
  const [chartUnit, setChartUnit] = useState(null);
  const [chartSlugInfo, setChartSlugInfo] = useState(null);
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const finel_next=searchParams.get('finel_next')=='true'?true:false;
  const INITIALFILTER_ENERGYPRICE = 'today';
  const handleInitialFilterOnToggle = () => {
    if (togglePriceCheck) {
      setInitialFilter(INITIALFILTER_ENERGYPRICE);
    } else {
      setInitialFilter(INITIALFILTER);
    }
  };

  useEffect(() => {
    setApiParams(
      widgetApiParams(
        'TotalEnergyUse',
        props,
        graphFilter,
        filterByDateCheck,
        apiDateRange,
        initialFilter,
        finel_next
      )
    );
    daySummaryDrilldownDate && setDaySummaryDrilldownDate(null);
  }, [graphFilter, apiDateRange, location.pathname, incomersValeue, generatorsValue,props?.plant_code]);

  useEffect(() => {
    charRef.current && charRef.current.chart.update(config);
  }, [config]);

  useEffect(() => {
    handleInitialFilterOnToggle();
  }, [togglePriceCheck]);

  useEffect(() => {
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: initialFilter
    }));
  }, [initialFilter]);

  useEffect(() => {
    if (comparisonViewCheck) {
      setTotalEnergyUseVisible(false)
      if (chartApiData2) {
        //    charRef.current && charRef.current.chart.series[0].remove(false)
        while (charRef.current.chart.series.length > 0)
          charRef.current.chart.series[0].remove(true);
        setConfig({
          ...comparisonTotalEnergyUseConfig({
            chartApiData: chartApiData2,
            graphFilter: comparisonFilter,
            userAccessibility: userAccessibility
          })
        });
      }
    } else if (togglePriceCheck) {
      setTotalEnergyUseVisible(false)
      if (chartApiData3) {
        while (charRef.current.chart.series.length > 0)
          charRef.current.chart.series[0].remove(true);
        setConfig({
          ...energyPriceTotalEnergyUseConfig({
            chartApiData: chartApiData3,
            graphFilter: graphFilter,
            userAccessibility: userAccessibility,
            drillDownLevel: props.drilldownlevel,
            daySummaryDrilldownDate: daySummaryDrilldownDate
          })
        });
      }
    }
    else if (incomersValeue || generatorsValue) {
      setTotalEnergyUseVisible(false)
      if (chartApiDataIncomersAndGenerator) {
        while (charRef.current.chart.series.length > 0)
          charRef.current.chart.series[0].remove(true);
        setConfig({
          ...IncomersAndGeneratosConfig({
            daySummaryDrilldownEnabled,
            props,
            chartApiData: chartApiDataIncomersAndGenerator,
            graphFilter: graphFilter,
            userAccessibility: userAccessibility,
            drillDownLevel: props.drilldownlevel,
            daySummaryDrilldownDate: daySummaryDrilldownDate,
            setDaySummaryDrilldownDate:setDaySummaryDrilldownDate
          })
        });
      }
    }
    else {
   
      if (chartApiData ) {
        if(drilldown){
          setTotalEnergyUseVisible(false)
        }
        else{
          setTotalEnergyUseVisible(true)
        }
        
        //  setChartData({...totalEnergyUseChartDataConfig(chartApiData,graphFilter,props.graph_name,props.drilldownlevel,userAccessibility,setDaySummaryDrilldownDate,daySummaryDrilldownDate,daySummaryDrilldownEnabled)})
        while (charRef.current.chart.series.length > 0)
          charRef.current.chart.series[0].remove(true);

        setConfig({
          ...totalEnergyUseChartDataConfig(
            chartApiData,
            graphFilter,
            props.graph_name,
            props.drilldownlevel,
            userAccessibility,
            setDaySummaryDrilldownDate,
            daySummaryDrilldownDate,
            daySummaryDrilldownEnabled,
            props.graph_tooltip,
            chartUnit,
            chartSlugInfo,
            props
          )
        });
      }
    }
  }, [chartApiData, chartApiData2, chartApiData3, chartApiDataIncomersAndGenerator]);

  // Toggle comparison and energy price view
  useEffect(() => {
    if (comparisonViewCheck) {
      setTogglePriceCheck(false);
      setIncomersValue(false)
      setGeneratorsValue(false)
    }
  }, [comparisonViewCheck]);

  useEffect(() => {
    if (togglePriceCheck) {
      setComparisonViewCheck(false);
      setIncomersValue(false)
      setGeneratorsValue(false)
    }
  }, [togglePriceCheck]);

  useEffect(()=>{
    if (incomersValeue|| generatorsValue){
      setComparisonViewCheck(false);
      setTogglePriceCheck(false);
    }
  },[incomersValeue,generatorsValue])
  useEffect(() => {
    graphApiCall();
  }, [apiParams, comparisonFilter, daySummaryDrilldownDate, togglePriceCheck, comparisonViewCheck,incomersValeue,generatorsValue]);


  useEffect(() => {
    if (data?.data) {
      if (data?.data?.total) {
        setTotalEnergyUse(data?.data.total || 0)
        // setTotalEnergyUseVisible(true)
      }

    }
    if (comparisonViewCheck && data && data.data) {
      //Comparison view
      let colors = ['#71B2FF', '#A2C3C5', '#C4C4C4', '#9B9B9B'];
      let seriesColorAdded = data?.data?.data
        .slice(0)
        .reverse()
        .map((item, i) => ({ ...item, color: colors[i] }));
      setChartApiData2(seriesColorAdded);
      //  setChartApiData([]);
    } else if (togglePriceCheck && data && data.data) {
      if (
        data?.data?.data?.energy_summary.length > 0 &&
        data?.data?.data?.energy_summary.every((el) => el === null)
      ) {
        setChartApiData3({ ...data?.data?.data, energy_summary: [] });
      } else {
        if (graphFilter.filterMain == '3months' || graphFilter.filterMain == '1year') {
          let imcompletColorAddedData = data?.data?.data?.energy_summary.map((item) => ({
            ...item,
            color: item && item.incomplete_flag ? '#C4C4C4' : null
          }));
          setChartApiData3({ ...data?.data?.data, energy_summary: imcompletColorAddedData });
        } else {
          let imcompletColorAddedData = data?.data?.data?.energy_summary.map((item) => ({
            ...item,
            color: item && item.incomplete_flag ? '#C4C4C4' : null
          }));
          setChartApiData3({ ...data?.data?.data, energy_summary: imcompletColorAddedData });
        }
      }
      charRef.current.chart.hideLoading();
    }
    else if (incomersValeue || generatorsValue && data && data.data) {
      // setChartApiData3(data.data.data)

      if (graphFilter.filterMain == '3months' || graphFilter.filterMain == '1year') {
        let imcompletColorAddedData = data?.data?.data?.sources?.map((item) => {
          let defaultColor = barColorCode?.filter((colors) => { if (colors?.name === item?.name) return colors?.color })?.map((colors) => (colors.color))?.[0]
          // color:barColorCode?.filter((item)=>{if(item.name===source.name) return item?.color})?.map((item)=>(item.color))?.[0],
          let processedArray = item?.data.map((props) => {
            if (props?.incomplete_flag) {
              return { ...props, 'color': '#C4C4C4' }
            }
            else {
              return { ...props, 'color': defaultColor }
            }
          })
          return ({
            ...item,
            data: processedArray,
            color:defaultColor
          })
        });

        setChartApiDataIncomersAndGenerator({ ...data?.data?.data, sources: imcompletColorAddedData });
      } else {
        let imcompletColorAddedData = data?.data?.data?.sources?.map((item) => {
          let defaultColor = barColorCode?.filter((colors) => { if (colors?.name === item?.name) return colors?.color })?.map((colors) => (colors.color))?.[0]

          return ({
            ...item,
            color: defaultColor
          })
        });

        setChartApiDataIncomersAndGenerator({ ...data?.data?.data, sources: imcompletColorAddedData });
      }
      charRef.current.chart.hideLoading();

      // setChartApiDataIncomersAndGenerator({ ...data?.data });
    }




    else {
      //Main view
      if (data && data.data) {
        if (graphFilter.filterMain == '3months' || graphFilter.filterMain == '1year') {
          let imcompletColorAddedData = data?.data?.data.map((item) => ({
            ...item,
            color: item.incomplete_flag ? '#C4C4C4' : null
          }));
          setChartApiData(imcompletColorAddedData);
        } else {
          let imcompletColorAddedData = data?.data?.data.map((item) => ({
            ...item,
            color: item.incomplete_flag ? '#C4C4C4' : null
          }));
          setChartApiData(imcompletColorAddedData);
        }

        if (data.data.unit_name) {
          setChartUnit(data.data.unit_name);
        }
        if (data.data.slug) {
          setChartSlugInfo(data.data.slug);
        }
      }
      charRef.current.chart.hideLoading();
    }
  }, [data]);

  useEffect(() => {
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: INITIALFILTER
    }));
    setTogglePriceCheck(false);
    setComparisonViewCheck(false);
  }, [onSidbarClick]);

  useEffect(() => {
    if (chartApiData && chartApiData.length > 7) {
      setHorScroll(chartApiData.length * 85);
    } else {
      setHorScroll(undefined);
      charRef.current &&
        charRef.current.chart.update({
          chart: {
            scrollablePlotArea: {
              minWidth: 'undefined'
            }
          }
        });
    }
  }, [chartApiData, graphFilter]);

  useEffect(() => {
    if (chartApiData && chartApiData.length > 7 && horScroll) {
      charRef.current.chart.update(config);
    }
  }, [horScroll, graphFilter]);

  return <></>;
}
