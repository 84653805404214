import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import CommonNavLink from "./CommonNavLink";
import { NavLink } from "react-router-dom";

export default function SidebarList({dataList,toggleActiveClass,kpi_view,energy_view}){
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
    const [openMenu, setOpenMenu] = useState(null);
    const [openNestedMenu, setOpenNestedMenu] = useState(null);
    const [openNestedChildMenu, setOpenNestedChildMenu] = useState(null);
    const [openNestedChildMenu1, setOpenNestedChildMenu1] = useState(null);  // New state for third-level menu
    const [navLinks, setNavLink] = useState([]);
  
    const toggleMenu = (menu) => {
      setOpenMenu(menu);
    };
  
    const toggleNestedMenu = (menu) => {
      setOpenNestedMenu(menu);
    };
  
    const toggleNestedChildMenu = (menu) => {
      setOpenNestedChildMenu(menu);
    };
  
    const toggleNestedChildMenu1 = (menu) => {  // New function to handle third-level menu
      setOpenNestedChildMenu1(menu);
    };
  
    // Helper function to determine if a menu has child layers
    const hasChildLayers = (menu) => menu?.layers && menu.layers.length > 0;
  
   const createNavLinks = (dataList) => {
      return dataList.map((unitMenu) => {
        if (unitMenu?.id) {
          return {
            name: unitMenu?.name,
            to:`/processes/${encodeURIComponent(unitMenu.name)}?process_type=${
              unitMenu.process_type
            }&process_id=${unitMenu.process_id}&sub_process_id=${
              unitMenu.sub_process_id
            }&kpi=${kpi_view}&energy=${energy_view}`,
            is_active: true,
            layers: unitMenu?.equipments? unitMenu?.equipments?.map((machinesMenu) => ({
              name: machinesMenu?.equipment_name,
              to:`/processes/${encodeURIComponent(unitMenu.name)}/${encodeURIComponent(
                          machinesMenu.equipment_name
                        )}?process_id=${unitMenu.process_id}&sub_process_id=${
                          unitMenu.sub_process_id
                        }&equipment_id=${
                          machinesMenu.equipment_id
                        }&kpi=${kpi_view}&energy=${energy_view}`,
              // to: `/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`,
              is_active:true,
         
            })):Object.keys(unitMenu.ugn_zones)?.map((eachZone) => ({
              name:eachZone,
         
              // to: `/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`,
              is_active:false,
              layers: unitMenu.ugn_zones[eachZone].equipments.map((machinesMenu) => ({
              name: machinesMenu?.equipment_name,
              is_active: true,
              to:`/processes/${encodeURIComponent(
                                      unitMenu.name
                                    )}/${encodeURIComponent(
                                      machinesMenu.equipment_name
                                    )}?process_type=${unitMenu.process_type}&process_id=${
                                      unitMenu.process_id
                                    }&sub_process_id=${unitMenu.sub_process_id}&equipment_id=${
                                      machinesMenu.equipment_id
                                    }&kpi=${kpi_view}&energy=${energy_view}`
    }))
         
            }))
          };
        }
        return null;
      }).filter(Boolean); // Filter out any null entries
    };
    useEffect(()=>{
      dataList?.length<=1?setNavLink(createNavLinks(dataList)[0]?.layers):setNavLink(createNavLinks(dataList))
  // setNavLink()
    },[dataList])
    return(<>{
      is_operator?  dataList.map((unitMenu, i) => {
        return (
          <div key={`${unitMenu.name}_${i}`} className={`${is_operator?'daz-sidebar__dropdown-wrp':'daz-sidebar__dropdown-wrp-right'}`}>
            <div
              onClick={toggleActiveClass}
              className={`daz-sidebar__hoverinfo--item ${is_operator?'daz-sidebar__dropdown-hov-left ':'daz-sidebar__dropdown-hov '} `}>
              <NavLink
                className={({ isActive }) => (isActive ? ' daz-sidebar__hoverinfo--active' : '')}
                to={`/processes/${encodeURIComponent(unitMenu.name)}?process_type=${
                  unitMenu.process_type
                }&process_id=${unitMenu.process_id}&sub_process_id=${
                  unitMenu.sub_process_id
                }&kpi=${kpi_view}&energy=${energy_view}`}>
                {' '}
                {unitMenu.name}
              </NavLink>
            </div>
  
            {unitMenu.equipments ? (
              <div className="daz-sidebar__dropdown shadow">
                {unitMenu.equipments.map((machinesMenu) => {
                  return (
                    <div key={machinesMenu.equipment_id} className="daz-sidebar__dropdown--item">
                      <NavLink
                        className={({ isActive }) =>
                          'daz-sidebar__hoverinfo--item' +
                          (isActive && ' daz-sidebar__hoverinfo--active')
                        }
                        to={`/processes/${encodeURIComponent(unitMenu.name)}/${encodeURIComponent(
                          machinesMenu.equipment_name
                        )}?process_id=${unitMenu.process_id}&sub_process_id=${
                          unitMenu.sub_process_id
                        }&equipment_id=${
                          machinesMenu.equipment_id
                        }&kpi=${kpi_view}&energy=${energy_view}`}>
                        {' '}
                        {machinesMenu.equipment_name}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            ) : unitMenu.ugn_zones ? (
              <div className="daz-sidebar__dropdown shadow">
                {Object.keys(unitMenu.ugn_zones).map((eachZone, i) => {
                  return (
                    <div key={i} className="daz-sidebar__dropdown--item p-0">
                      <div
                        onClick={toggleActiveClass}
                        className={'daz-sidebar__dropdown-wrp w-100'}>
                        <div className="daz-sidebar__hoverinfo--item   daz-sidebar__dropdown-hov ">
                          {eachZone}
                        </div>
                        {unitMenu.ugn_zones[eachZone].equipments ? (
                          <div className="daz-sidebar__dropdown shadow">
                            {unitMenu.ugn_zones[eachZone].equipments.map((machinesMenu) => {
                              return (
                                <div
                                  key={machinesMenu.equipment_id}
                                  className="daz-sidebar__dropdown--item">
                                  <NavLink
                                    className={({ isActive }) =>
                                      'daz-sidebar__hoverinfo--item' + isActive &&
                                      'daz-sidebar__hoverinfo--active'
                                    }
                                    to={`/processes/${encodeURIComponent(
                                      unitMenu.name
                                    )}/${encodeURIComponent(
                                      machinesMenu.equipment_name
                                    )}?process_type=${unitMenu.process_type}&process_id=${
                                      unitMenu.process_id
                                    }&sub_process_id=${unitMenu.sub_process_id}&equipment_id=${
                                      machinesMenu.equipment_id
                                    }&kpi=${kpi_view}&energy=${energy_view}`}>
                                    {' '}
                                    {machinesMenu.equipment_name}
                                  </NavLink>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      }): <div className="sidebar">
      {navLinks?.map((plantMenu) => {
        return plantMenu?.is_active ? (
          <>
            <div
            style={{marginBottom:'0px'}}
              className={`menu-item nested-child-child-item-list ${hasChildLayers(plantMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
              onMouseEnter={() => toggleMenu('menu1')}
              onMouseLeave={() => toggleMenu(null)}
            >
              {/* ========================plant=================================== */}
              <CommonNavLink
                to={plantMenu?.to}
                activeClassName="active"
              >
                {plantMenu?.name}
              </CommonNavLink>
              {openMenu === 'menu1' && hasChildLayers(plantMenu) && (
                <div className="child-menu shadow">
                  {plantMenu?.layers?.map((processMenu) => {
                    return (
                      <>
                        <div
                          className={`child-item nested-child-child-item-list ${hasChildLayers(processMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                          onMouseEnter={() => toggleNestedMenu('nested1')}
                          onMouseLeave={() => toggleNestedMenu(null)}
                        >
                          {/* ========================process=================================== */}
                          <CommonNavLink
                           to={processMenu?.to}
                            // to={`/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`}
                            activeClassName="active" className="child-link"
                          >
                            {processMenu?.name}
                          </CommonNavLink>
                          {openNestedMenu === 'nested1' && hasChildLayers(processMenu) && (
                            <div
                              className="nested-child-menu shadow "
                              onMouseEnter={() => toggleNestedChildMenu('nestedChild1')}
                            >
                              {processMenu?.layers?.map((equipmentMenu) => {
                                return (
                                  <>
                                    <div
                                      className={`nested-child-item nested-child-child-item-list ${hasChildLayers(equipmentMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                                      onMouseLeave={() => toggleNestedChildMenu(null)}
                                    >
                                      {/* ========================equipment=================================== */}
                                      <CommonNavLink
                                       to={equipmentMenu?.to}
                                        // to={`/layer_equipment/${processMenu?.name}?/${encodeURIComponent(equipmentMenu?.name)}?process_type=${equipmentMenu.layer_type}&equipment_id=${equipmentMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&process_id=${processMenu?.layer_id}&plant_id=${plantMenu?.layer_id}`}
                                        activeClassName="active" className="nested-child-link"
                                      >
                                        {equipmentMenu?.name}
                                      </CommonNavLink>
                                      {openNestedChildMenu === 'nestedChild1' && hasChildLayers(equipmentMenu) && (
                                        <div
                                          className="nested-child-child-menu shadow"
                                          onMouseEnter={() => toggleNestedChildMenu1('nestedChild2')}
                                        >
                                          {equipmentMenu?.layers?.map((componentMenu) => {
                                            return (
                                              <>
                                                <div
                                                  className={`nested-child-child-item nested-child-child-item-list ${hasChildLayers(componentMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                                                  onMouseLeave={() => toggleNestedChildMenu1(null)}
                                                >
                                                  {/* ========================component=================================== */}
                                                  <CommonNavLink
                                                  to={componentMenu?.to}
                                                    // to={`/${componentMenu.layer_type}/${encodeURIComponent(componentMenu?.name)}?process_type=${componentMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}&component_id=${componentMenu?.layer_id}`}
                                                    activeClassName="active" className="nested-child-child-link"
                                                  >
                                                    {componentMenu?.name}
                                                  </CommonNavLink>
                                                  {openNestedChildMenu1 === 'nestedChild2' && hasChildLayers(componentMenu) && (
                                                    <div className="nested-child-child-child-menu shadow">
                                                      {/* Add third-level nested links here */}
                                              
                                                    </div>
                                                  )}
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        ) : ('');
      })}
    </div>
    }
    </>
    )
  }