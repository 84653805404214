
/* eslint-disable no-debugger */
function widgetApiParams(
  widgetName,
  props,
  graphFilter,
  filterByDateCheck,
  apiDateRange,
  INITIALFILTER,
  finel_next
) {
  console.log("filterByDateCheck",props.drilldownlevel)
  const filterMain = graphFilter.filterMain;
  const filterSecondary = graphFilter.filterSecondary;
  const filterDateRange = filterByDateCheck
    ? { start_date_time: apiDateRange[0], end_date_time: apiDateRange[1] }
    : null;
  const filterDateTimeRange = filterByDateCheck
  ? { start_date_time: apiDateRange[0], end_date_time: apiDateRange[1] }
  : null;
  const plantCode=props.plant_code
  // debugger
    // let [searchParams] = useSearchParams();
  // const finel_next=searchParams.get('finel_next')=='true'?true:false;
  // const sensorParams={directly_tagged_sensor:finel_next?1:0}

  switch (props.drilldownlevel) {

    case 'Home':{
      const plantDurationParams = { duration: filterMain };
      const plantInitialParams = { duration: INITIALFILTER };
      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...plantDurationParams }
            : filterByDateCheck
            ? { ...filterDateRange }
            : { ...plantInitialParams };

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...plantDurationParams }
            : filterByDateCheck
            ? { ...filterDateRange }
            : { ...plantInitialParams };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...plantDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power'
              }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange , graph_type: 'instantaneous_power'}
            : { ...plantDurationParams, graph_type: 'instantaneous_power' };

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...plantDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load' }
            : { ...plantDurationParams, graph_type: 'peak_load' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...plantDurationParams, frequency: filterSecondary.key }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange }
            : { ...plantDurationParams };
      }
      break;
    }
    case 'Company': {
      const plantDurationParams = { duration: filterMain };
      const plantInitialParams = { duration: INITIALFILTER };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...plantDurationParams ,'layer_type':'company'}
            : filterByDateCheck
            ? { ...filterDateRange ,'layer_type':'company'}
            : { ...plantInitialParams ,'layer_type':'company'};

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...plantDurationParams,'layer_type':'company' }
            : filterByDateCheck
            ? { ...filterDateRange ,'layer_type':'company'}
            : { ...plantInitialParams ,'layer_type':'company'};

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...plantDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power',
                'layer_type':'company'
              }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange , graph_type: 'instantaneous_power','layer_type':'company'}
            : { ...plantDurationParams, graph_type: 'instantaneous_power' ,'layer_type':'company'};

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...plantDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load','layer_type':'company' }
            : { ...plantDurationParams, graph_type: 'peak_load' ,'layer_type':'company'};

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...plantDurationParams, frequency: filterSecondary.key,'layer_type':'company' }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange ,'layer_type':'company'}
            : { ...plantDurationParams ,'layer_type':'company'};
      }
      break;
    }
    case 'Process': {
      const processParams = {
        process_type: props.process_type,
        process_id: props.process_id,
        sub_process_id: props.sub_process_id
      };
      const processDurationParams = { duration: filterMain, ...processParams };
      const processInitialParams = { duration: INITIALFILTER, ...processParams };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateRange, ...processParams }
            : { ...processInitialParams };

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams }
            : filterByDateCheck 
            ? { ...filterDateRange, ...processParams }
            : { ...processInitialParams };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...processDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power'
              }
              : filterByDateCheck && graphFilter.filterMain == ''
              ? { ...filterDateTimeRange, ...processParams, graph_type: 'instantaneous_power' }
            : { ...processDurationParams, graph_type: 'instantaneous_power' };

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load' }
            : { ...processDurationParams, graph_type: 'peak_load' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...processParams }
            : { ...processDurationParams };
      }
      break;
    }
    case 'Plant': {
      const processParams = {
        layer_type: props.process_type,
        layer_id: props.plant_id,
        plant_code: props.plant_code
        // plant_code: '1005'
      };
      const processDurationParams = { duration: filterMain, ...processParams };
      const processInitialParams = { duration: INITIALFILTER, ...processParams };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type||'plant',  plant_code: plantCode}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type||'plant',  plant_code: plantCode}
            : { ...processInitialParams ,'layer_type':props?.process_type||'plant',  plant_code: plantCode};

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type||'plant'}
            : filterByDateCheck 
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type||'plant'}
            : { ...processInitialParams,'layer_type':props?.process_type||'plant' };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...processDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power',
                'layer_type':props?.process_type||'plant'
              }
              : filterByDateCheck && graphFilter.filterMain == ''
              ? { ...filterDateTimeRange, ...processParams, graph_type: 'instantaneous_power','layer_type':props?.process_type||'plant' }
            : { ...processDurationParams, graph_type: 'instantaneous_power' ,'layer_type':props?.process_type||'plant'};

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load','layer_type':props?.process_type||'plant' }
            : { ...processDurationParams, graph_type: 'peak_load','layer_type':props?.process_type||'plant' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key ,'layer_type':props?.process_type||'plant'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...processParams,'layer_type':props?.process_type||'plant' }
            : { ...processDurationParams ,'layer_type':props?.process_type||'plant'};
      }
      break;
    }
    case 'ProcessLayer':{
      const processParams = {
        layer_type: props.process_type,
        layer_id: props.process_id,
        plant_code: plantCode,directly_tagged_sensor:finel_next?1:0
      };
      const processDurationParams = { duration: filterMain, ...processParams };
      const processInitialParams = { duration: INITIALFILTER, ...processParams };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type||'process'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type||'process'}
            : { ...processInitialParams ,'layer_type':props?.process_type||'process'};

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type||'process'}
            : filterByDateCheck 
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type||'process'}
            : { ...processInitialParams,'layer_type':props?.process_type||'process' };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...processDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power',
                'layer_type':props?.process_type||'process'
              }
              : filterByDateCheck && graphFilter.filterMain == ''
              ? { ...filterDateTimeRange, ...processParams, graph_type: 'instantaneous_power','layer_type':props?.process_type||'process' }
            : { ...processDurationParams, graph_type: 'instantaneous_power' ,'layer_type':props?.process_type||'process'};

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load','layer_type':props?.process_type||'process' }
            : { ...processDurationParams, graph_type: 'peak_load','layer_type':props?.process_type||'process' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key ,'layer_type':props?.process_type||'process'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...processParams,'layer_type':props?.process_type||'process' }
            : { ...processDurationParams ,'layer_type':props?.process_type||'process'};
      }
      break;
    }
    
    case 'ComponentLayer':{
      const processParams = {
        layer_type:'component',
        layer_id: props.component_id,
        plant_code: plantCode,
        directly_tagged_sensor:finel_next?1:0
        // plant_code: '1005'
      };
      const processDurationParams = { duration: filterMain, ...processParams };
      const processInitialParams = { duration: INITIALFILTER, ...processParams };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':'component'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateRange, ...processParams ,'layer_type':'component'}
            : { ...processInitialParams ,'layer_type':'component'};

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':'component'}
            : filterByDateCheck 
            ? { ...filterDateRange, ...processParams ,'layer_type':'component'}
            : { ...processInitialParams,'layer_type':'component' };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...processDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power',
                'layer_type':'component'
              }
              : filterByDateCheck && graphFilter.filterMain == ''
              ? { ...filterDateTimeRange, ...processParams, graph_type: 'instantaneous_power','layer_type':'component' }
            : { ...processDurationParams, graph_type: 'instantaneous_power' ,'layer_type':'component'};

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load','layer_type':'component' }
            : { ...processDurationParams, graph_type: 'peak_load','layer_type':'component' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key ,'layer_type':'component'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...processParams,'layer_type':'component' }
            : { ...processDurationParams ,'layer_type':'component'};
      }
      break;
    }
    case 'EquipmentLayer':{
      const processParams = {
        layer_type: props.process_type,
        layer_id: props.equipment_id,
        plant_code: plantCode,
        directly_tagged_sensor:finel_next?1:0
        // plant_code: '1005'
      };
      const processDurationParams = { duration: filterMain, ...processParams };
      const processInitialParams = { duration: INITIALFILTER, ...processParams };

      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type||'equipment'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type||'equipment'}
            : { ...processInitialParams ,'layer_type':props?.process_type || 'equipment'};

        case 'ProcessEnergyUse':
          return filterMain != ''
            ? { ...processDurationParams ,'layer_type':props?.process_type || 'equipment'}
            : filterByDateCheck 
            ? { ...filterDateRange, ...processParams ,'layer_type':props?.process_type || 'equipment'}
            : { ...processInitialParams,'layer_type':props?.process_type || 'equipment' };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...processDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power',
                'layer_type':props?.process_type || 'equipment'
              }
              : filterByDateCheck && graphFilter.filterMain == ''
              ? { ...filterDateTimeRange, ...processParams, graph_type: 'instantaneous_power','layer_type':props?.process_type || 'equipment' }
            : { ...processDurationParams, graph_type: 'instantaneous_power' ,'layer_type':props?.process_type || 'equipment'};

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load','layer_type':props?.process_type || 'equipment' }
            : { ...processDurationParams, graph_type: 'peak_load','layer_type':props?.process_type || 'equipment' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...processDurationParams, frequency: filterSecondary.key ,'layer_type':props?.process_type || 'equipment'}
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...processParams,'layer_type':props?.process_type || 'equipment' }
            : { ...processDurationParams ,'layer_type':props?.process_type || 'equipment'};
      }
      break;
    }
    case 'Machine': {
   
      const machineParams = {
        process_type: props.process_type,
        process_id: props.process_id,
        sub_process_id: props.sub_process_id,
        equipment_id: props.equipment_id,
        directly_tagged_sensor:finel_next?1:0
      };
      const layeredMachineParams={
        layer_type:props?.graphInfo?.layer_type,//props?.graphInfo?.layer_type
        plant_code: props.plant_code,
        layer_id:props?.graphInfo?.layer_id
      };
      const machineDurationParams = { duration: filterMain,...(props?.flexibleEquipmentEnabled?layeredMachineParams:machineParams )};
      const machineInitialParams = { duration: INITIALFILTER, ...(props?.flexibleEquipmentEnabled?layeredMachineParams:machineParams) };
     
      switch (widgetName) {
        case 'TotalEnergyUse':
          return filterMain != ''
            ? { ...machineDurationParams }
            : filterByDateCheck
            ? { ...filterDateRange, ...machineParams }
            : { ...machineInitialParams };

        case 'InstantaneousPower':
          return filterSecondary
            ? {
                ...machineDurationParams,
                frequency: filterSecondary.key,
                graph_type: 'instantaneous_power'
              }
            : { ...machineDurationParams, graph_type: 'instantaneous_power' };

        case 'InstantaneousPowerPeak':
          return filterSecondary
            ? { ...machineDurationParams, frequency: filterSecondary.key, graph_type: 'peak_load' }
            : { ...machineDurationParams, graph_type: 'peak_load' };

        case 'ProcessInstantaneousPower':
          return filterSecondary
            ? { ...machineDurationParams, frequency: filterSecondary.key }
            : filterByDateCheck && graphFilter.filterMain == ''
            ? { ...filterDateTimeRange, ...machineParams }
            : { ...machineDurationParams };
      }
      break;
    }
    default:
      return;
  }
}

export default widgetApiParams;
