import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
import DateRangePicker from '../../../common/datetimepicker/daterangepicker';
import DownloadChartContainer from '../../../common/downloadchart/containers/downloadchart.container';
import ChartTopFilterContainer from '../../../common/charttopfilter/containers/charttopfilter.container';
import ChartSettingsContainer from '../../../common/chartsettings/containers/chartsettings.container';

CustomEvents(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default function ProcessLayerComponent({
  chartData,
  jsChartCallback,
  charRef,
  hasFilter,
  graphFilter,
  graphFilterObject,
  setGraphFilter,
  hasSettings,
  filterByDate,
  filterByDateCheck,
  setFilterByDateCheck,
  dateRange,
  setDateRange,
  graphInfo
}) {
  return (
    <div className="process-page">
      <h4 className="text-center layout-heading">Plant22</h4>

      <div className="chart-bg">
        <div className="flex-btw top-functions">
          <div className="flex-c">
            {hasFilter ? (
              <div className="flex-c">
                <ChartTopFilterContainer
                  graphFilterObject={graphFilterObject}
                  graphFilter={graphFilter}
                  setGraphFilter={setGraphFilter}
                />
              </div>
            ) : (
              ''
            )}
            <label className="flex-c">
              {filterByDate ? (
                <i
                  onClick={() => {
                    setFilterByDateCheck((prev) => !prev);
                  }}
                  className={
                    filterByDateCheck
                      ? 'calendar-icon material-icons notranslate graphoptionicons material-icon-grey-active'
                      : 'calendar-icon material-icons notranslate graphoptionicons'
                  }>
                  calendar_month_mutlined_icon
                </i>
              ) : (
                ''
              )}

              {filterByDateCheck ? (
                <div className="filter_by_date--inputs">
                  <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} graphInfo={graphInfo} />
                </div>
              ) : (
                ''
              )}
            </label>
          </div>
          <div className="flex-c">
            <DownloadChartContainer charRef={charRef} />

            {hasSettings ? (
              <ChartSettingsContainer
                chartId="process"
                filterByDateCheck={filterByDateCheck}
                setFilterByDateCheck={setFilterByDateCheck}
                filterByDate={filterByDate}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="highcharts-wrp">
          
          <HighchartsReact
            ref={charRef}
            callback={jsChartCallback}
            highcharts={Highcharts}
            options={chartData}
            containerProps={{ style: { height: '100%' } }}
          />
        </div>
        {/* {
                hasIncompletePeriodLegend ?
                <div className="custom-legend incompletePeriod"><span class="bar-grey"></span>Incomplete period</div>
                :
                ''
                } */}
      </div>
    </div>
  );
}
