import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
export default function ChartSettingsComponent({
  comparisonView,
  comparisonViewCheck,
  setComparisonViewCheck,
  togglePriceCheck,
  setTogglePriceCheck,
  machineKpi,
  setKpiMachineStatus,
  machineStatus,
  setOpenMachineStatusSlider,
  setCurrentKpiId,
  kpiId,
  setCurrentKpiUnitId,
  kpiUnitId,
  setOpenEnterKpiSlider,
  isCustomGraph,
  isGreen,
  setCustomApiDetails,
  kpiGraphDetails,
  incomersFilter,
  generatorFilter,
  setIncomersValue,
  setGeneratorsValue,
  incomersValeue,
  generatorsValue,
  toggleHumidity,
  setToggleHumidity,
  hasHumidityToggle,
  IncomerrsGeneratorToggle
}) {
  const isVariablePricing = useSelector((state) => state.company.is_variable_pricing_enabled);

  const handleSetCustomKpi = () => {
    setCurrentKpiId('custom');

    setCustomApiDetails((prev) => ({
      ...prev,
      custom_kpi_name: {
        ...prev.custom_kpi_name,
        value: kpiGraphDetails.kpi.name,
        id: kpiGraphDetails.kpi.id
      },
      custom_kpi_unit: {
        ...prev.custom_kpi_unit,
        value: kpiGraphDetails.kpi_unit.name,
        id: kpiGraphDetails.kpi_unit.id
      }
    }));
  };

  return (
    <Dropdown
      className="daz-icon__dropdown chart-settings-dropdown"
      autoClose="outside"
      align="end">
      <Dropdown.Toggle>
        <OverlayTrigger placement="top" overlay={<Tooltip>Settings</Tooltip>}>
          <i
            className={`${
              isGreen ? 'green' : ''
            } ms-1 material-icons notranslate graphoptionicons daz-icon__dropdown-settings`}>
            settings
          </i>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {machineKpi ? (
          <>
            <Dropdown.Item>
              <button
                className="txt-12 w-100 text-start "
                onClick={() => {
                  isCustomGraph ? handleSetCustomKpi() : setCurrentKpiId(kpiId);
                  setKpiMachineStatus(false);
                  setCurrentKpiUnitId(kpiUnitId);
                }}>
                Set KPI
              </button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button
                className="txt-12  w-100 text-start"
                onClick={() => setOpenEnterKpiSlider(true)}>
                Enter KPI
              </button>
            </Dropdown.Item>
          </>
        ) : machineStatus ? (
          <>
            <Dropdown.Item>
              <button
                className="txt-12 w-100 text-start "
                onClick={() => {
                  setOpenMachineStatusSlider(true);
                }}>
                Customize values
              </button>
            </Dropdown.Item>
          </>
        ) : (
          <>
            {comparisonView ? (
              <div className="flex-btw chart-settings-dropdown_item">
                <p>Comparison view</p>
                <label className="daz-switch small">
                  <input
                    type="checkbox"
                    checked={comparisonViewCheck}
                    onChange={() => {
                      setComparisonViewCheck((prev) => !prev);
                    }}
                  />
                  <span className="slider  round"></span>
                </label>
              </div>
            ) : (
              ''
            )}
            {
              !hasHumidityToggle && <div className="flex-btw chart-settings-dropdown_item">
              <p>Energy price</p>
              <label className="daz-switch small" style={{ opacity: isVariablePricing ? 1 : 0.5 }}>
                <input
                  type="checkbox"
                  checked={togglePriceCheck}
                  disabled={isVariablePricing ? false : true}
                  onChange={() => {
                    setTogglePriceCheck((prev) => !prev);
                  }}
                />
                <span
                  className="slider  round"
                  style={{ cursor: isVariablePricing ? 'pointer' : 'default' }}></span>
              </label>
            </div>
            }
           
           {
              hasHumidityToggle &&    <div className="flex-btw chart-settings-dropdown_item">
              <p>Humidity overlay</p>
              <label className="daz-switch small" >
                <input
                  type="checkbox"
                  checked={toggleHumidity}
                  // disabled={isVariablePricing ? false : true}
                  onChange={() => {
                    setToggleHumidity((prev) => !prev);
                  }}
                />
                <span
                  className="slider  round"
                  style={{ cursor: isVariablePricing ? 'pointer' : 'default' }}></span>
              </label>
            </div>
               }
            {/* ================================================================== */}
            {
              IncomerrsGeneratorToggle?(
                <div className="flex-btw chart-settings-dropdown_item" style={{ opacity: incomersFilter ? 1 : 0.5 }}>
                <p>Purchased energy</p>
                <label className="daz-switch small">
                  <input
                    type="checkbox"
                  // disabled={isVariablePricing ? false : true}
                    checked={incomersValeue}
                    disabled={!incomersFilter}
                    onChange={() => {
                      setIncomersValue((prev) => !prev);
                    }}
                  />
                  <span
                    className="slider  round"
                    style={{ cursor: isVariablePricing ? 'pointer' : 'default' }}></span>
                </label>
              </div>
              ):('')
            }
          {
            IncomerrsGeneratorToggle?(
              <div className="flex-btw chart-settings-dropdown_item" style={{ opacity: generatorFilter ? 1 : 0.5 }}>
              <p>Produced energy</p>
              <label className="daz-switch small" >
                <input
                  type="checkbox"
                  // disabled={isVariablePricing ? false : true}
                  checked={generatorsValue}
                  disabled={!generatorFilter}
                  onChange={() => {
                    setGeneratorsValue((prev) => !prev);
                  }}
                />
                <span
                  className="slider  round"
                  style={{ cursor: isVariablePricing ? 'pointer' : 'default' }}></span>
              </label>
            </div>
            ):('')
          }
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
