import React from 'react';
import { NavLink } from 'react-router-dom';

const CommonNavLink = ({ to, activeClassName, className, children }) => {
  return (
    <NavLink to={to} activeClassName={activeClassName} className={className}>
      <span className='mr-2'>
      {children} &nbsp;&nbsp;  
        
      </span>
    </NavLink>
  );
};

export default CommonNavLink;