function ComparisionApiParams(widgetName, props, graphFilter) {
  const filterMain = graphFilter.filterMain;
  const filterSecondary = graphFilter.filterSecondaryValue;
  const mainParams = { comparison_type: filterMain, comparison_duration: filterSecondary };
  const plantCode=props?.plant_code
  
  // localStorage.getItem('plantCode')
  const drildownLevels={
    'Company':'company',
    'Plant':'plant',
    'ProcessLayer':'process',
    'EquipmentLayer':'equipment',
    'ComponentLayer':'component',
  }
  
  switch (props.drilldownlevel) {
    case 'Home': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams };
      }
      break;
    }
    case 'Company': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams,layer_type:drildownLevels[`${props.drilldownlevel}`] };
      }
      break;
    }
    case 'Plant': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams,layer_type:drildownLevels[`${props.drilldownlevel}`],plant_code:plantCode,layer_id:props?.plant_id };
      }
      break
    }
    case 'ProcessLayer': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams,layer_type:drildownLevels[`${props.drilldownlevel}`],plant_code:plantCode,layer_id:props?.process_id };
      }
      break
    }
    case 'EquipmentLayer': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams,layer_type:drildownLevels[`${props.drilldownlevel}`],plant_code:plantCode,layer_id:props?.equipment_id };
      }
      break
    }
    case 'ComponentLayer': {
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams,layer_type:drildownLevels[`${props.drilldownlevel}`],plant_code:plantCode,layer_id:props?.component_id };
      }
      break
    }
    case 'Process': {
      const processParams = {
        process_type: props.process_type,
        process_id: props.process_id,
        sub_process_id: props.sub_process_id
      };
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams, ...processParams };
      }
      break;
    }
    case 'Machine': {
      const machineParams = {
        process_type: props.process_type,
        process_id: props.process_id,
        sub_process_id: props.sub_process_id,
        equipment_id: props.equipment_id
      };
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams, ...machineParams };
      }
      break;
    }
    case 'equipment_layer': {
      const machineParams = {
        process_type: props.process_type,
        process_id: props.process_id,
        sub_process_id: props.sub_process_id,
        equipment_id: props.equipment_id
      };
      switch (widgetName) {
        case 'TotalEnergyUse':
          return { ...mainParams, ...machineParams };
      }
      break;
    }
    default:
      return;
  }
}

export default ComparisionApiParams;
