import {
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  axisLablesFontSizeSet,
  chartYaxisConfig
} from '../../../config/chartCommon';
import { roundToTwoDecimal } from '../../../utilities/common.computations';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
import ReactGA from 'react-ga4';

function processEnergyUseChartConfig({
  chartApiData,
  props,
  location,
  navigate,
  setZoneId,
  graphName,
  userAccessibility
}) {
  const is_zone_enabled = new URLSearchParams(location.search).get('is_zone_enabled');
  const process_id = new URLSearchParams(location.search).get('process_id');
  const plant_id = new URLSearchParams(location.search).get('plant_id');
  const equipment_id = new URLSearchParams(location.search).get('equipment_id');
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  const is_fontsize_set =
    userAccessibility && userAccessibility.text_size_percentage != 100 ? true : false;
  var config = {
    ...chartTitleConfig({ graphTitle: graphName, userAccessibility: userAccessibility }),
    ...chartCommonConfig,
    chart: {
      className: 'process-energy-use',
      ...chartChartConfig({ userAccessibility: userAccessibility })
      // scrollablePlotArea: {
      //   minWidth: horScroll,
      // },
    },

    exporting: {
      chartOptions: {
        chart: {
          spacingBottom: 20
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}'
            }
          }
        }
      }
    },

    plotOptions: {
      column: {
        cursor: 'pointer',
        color: chartApiData && chartApiData.incomplete_flag ? '#C4C4C4' : '#71B2FF',
        minPointLength: 3,
        point: {
          events: {
            click: function (data) {
              if (props.drilldownlevel == 'Home') {
                if (data.point.is_zone_enabled) {
                  navigate(
                    `/processes/${encodeURIComponent(data.point.category)}?process_type=${
                      data.point.process_type
                    }&process_id=${data.point.process_id}&sub_process_id=${
                      data.point.sub_process_id
                    }&is_zone_enabled=${data.point.is_zone_enabled}`
                  );
                  ReactGA.event({
                    category: 'Process bar clicked from plant level',
                    action: 'Clicked Process Chart Bar - Plant level',
                    label: `Clicked ${encodeURIComponent(data.point.category)}`
                  });
                } else {
                  navigate(
                    `/processes/${encodeURIComponent(data.point.category)}?process_type=${
                      data.point.process_type
                    }&process_id=${data.point.process_id}&sub_process_id=${
                      data.point.sub_process_id
                    }`
                  );
                  ReactGA.event({
                    category: 'Process bar clicked from plant level',
                    action: 'Clicked Process Chart Bar - Plant level',
                    label: `Clicked ${encodeURIComponent(data.point.category)}`
                  });
                }
              } else if (props.drilldownlevel == 'Process') {
                if (is_zone_enabled) {
                  if (data.point.zone_id) {
                    setZoneId(data.point.zone_id);
                  } else {
                    navigate(
                      `${location.pathname}/${encodeURIComponent(
                        data.point.category
                      )}?&process_id=${data.point.process_id}&sub_process_id=${
                        data.point.sub_process_id
                      }&equipment_id=${data.point.equipment_id}&is_zone_enabled=${is_zone_enabled}`
                    );
                    ReactGA.event({
                      category: 'Process bar clicked from process level',
                      action: 'Clicked Process Chart Bar - Process level',
                      label: `Clicked ${encodeURIComponent(data.point.category)}`
                    });
                  }
                } else {
                  navigate(
                    `${location.pathname}/${encodeURIComponent(data.point.category)}?&process_id=${
                      data.point.process_id
                    }&sub_process_id=${data.point.sub_process_id}&equipment_id=${
                      data.point.equipment_id
                    }`
                  );
                  ReactGA.event({
                    category: 'Process bar clicked from process level',
                    action: 'Clicked Process Chart Bar - Process level',
                    label: `Clicked ${encodeURIComponent(data.point.category)}`
                  });
                }
              }
             else if(data.point.is_drill_down_active){
             
                 if (props.drilldownlevel == 'Company') {

                    localStorage.setItem('plantCode',data.point.plant_code)
                    if (data.point.is_zone_enabled) {
                      navigate(
                        `/plant/${encodeURIComponent(data.point.category)}?process_type=${
                          data.point.layer_type
                        }&plant_id=${data.point.layer_id}&plant_code=${data.point.plant_code}`
                      );
                      ReactGA.event({
                        category: 'Process bar clicked from plant level',
                        action: 'Clicked Process Chart Bar - Plant level',
                        label: `Clicked ${encodeURIComponent(data.point.category)}`
                      });
                    } else {
                      navigate(
                        `/plant/${encodeURIComponent(data.point.category)}?process_type=${
                          data.point.layer_type
                        }&plant_id=${data.point.layer_id}&plant_code=${data.point.plant_code}`
                      );
                      ReactGA.event({
                        category: 'Process bar clicked from plant level',
                        action: 'Clicked Process Chart Bar - Plant level',
                        label: `Clicked ${encodeURIComponent(data.point.category)}`
                      });
                    }
                }
                else if (props.drilldownlevel == 'Plant') {
                  if (is_zone_enabled) {
                    if (data.point.zone_id) {
                      setZoneId(data.point.zone_id);
                    } else {
                      navigate(
                        `/layer_process/${encodeURIComponent(data.point.category)}?process_type=${
                          data.point.layer_type
                        }&process_id=${data.point.process_id||data.point.layer_id}&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point?.plant_code}`
                      );
                      ReactGA.event({
                        category: 'Process bar clicked from process level',
                        action: 'Clicked Process Chart Bar - Process level',
                        label: `Clicked ${encodeURIComponent(data.point.category)}`
                      });
                    }
                  } else {
                    navigate(
                      `/layer_process/${encodeURIComponent(data.point.category)}?&process_type=${data?.point?.layer_type}&process_id=${
                        data.point.process_id||data.point.layer_id
                      }&process_type=${
                          data.point.layer_type
                        }&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point.plant_code}&finel_next=${data?.point?.next_drill_down_is_final || false}`
                    );
                    ReactGA.event({
                      category: 'Process bar clicked from process level',
                      action: 'Clicked Process Chart Bar - Process level',
                      label: `Clicked ${encodeURIComponent(data.point.category)}`
                    });
                  }
                }
                else if (props.drilldownlevel == 'ProcessLayer') {
                  if (is_zone_enabled) {
                    if (data.point.zone_id) {
                      setZoneId(data.point.zone_id);
                    } else {
                      navigate(
                        `/layer_equipment/${encodeURIComponent(data.point.category)}&process_type=${
                          data.point.layer_type
                        }&equipment_id=${data.point.layer_id}&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point.plant_code}&process_id=${this.series.userOptions.layerParams?.process_id}`
                      );
             
                      ReactGA.event({
                        category: 'Process bar clicked from process level',
                        action: 'Clicked Process Chart Bar - Process level',
                        label: `Clicked ${encodeURIComponent(data.point.category)}`
                      });
                    }
                  } else {
                    navigate(
                      `/layer_equipment/${encodeURIComponent(data.point.category)}?&&equipment_id=${data.point.layer_id}&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point?.plant_code}&process_id=${this.series.userOptions.layerParams?.process_id}&process_type=${
                          data.point.layer_type
                        }&finel_next=${data?.point?.next_drill_down_is_final || false}`
                    );
                    ReactGA.event({
                      category: 'Process bar clicked from process level',
                      action: 'Clicked Process Chart Bar - Process level',
                      label: `Clicked ${encodeURIComponent(data.point.category)}`
                    });
                  }
                }
                else if (props.drilldownlevel == 'EquipmentLayer') {
                  if (is_zone_enabled) {
                    if (data.point.zone_id) {
                      setZoneId(data.point.zone_id);
                    } else {
                      navigate(
                        `/component/${encodeURIComponent(data.point.category)}&process_type=${
                          data.point.layer_type
                        }&component_id=${data.point.layer_id}&equipment_id=${this.series.userOptions.layerParams?.equipment_id}&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point?.plant_code}&process_id=${this.series.userOptions.layerParams?.process_id}`
                      );
                      ReactGA.event({
                        category: 'Process bar clicked from process level',
                        action: 'Clicked Process Chart Bar - Process level',
                        label: `Clicked ${encodeURIComponent(data.point.category)}`
                      });
                    }
                  } else {
                    navigate(
                      `/component/${encodeURIComponent(data.point.category)}?&&component_id=${data.point.layer_id}&equipment_id=${this.series.userOptions.layerParams?.equipment_id}&plant_id=${this.series.userOptions.layerParams?.plant_id}&plant_code=${data?.point.plant_code}&process_id=${this.series.userOptions.layerParams?.process_id}&process_type=${
                          data.point.layer_type
                        }&finel_next=${data?.point?.next_drill_down_is_final || false}`
                    );
                    ReactGA.event({
                      category: 'Process bar clicked from process level',
                      action: 'Clicked Process Chart Bar - Process level',
                      label: `Clicked ${encodeURIComponent(data.point.category)}`
                    });
                  }
                }
                
              }
         
            }
          }
        }
      }
    },
    xAxis: {
      lineColor: '#cccccc',
      categories: chartApiData && chartApiData.xaxis_categories,
      labels: {
        // autoRotation: chartApiData && xaxisLength > 10 ? [0-45] : undefined,
        autoRotation: is_operator && is_fontsize_set ? [-45] : undefined,
        padding: 3,
        events: {
          click: function () {
            var xaxisLableElement = document.querySelectorAll(
              '.process-energy-use .highcharts-point'
            )[this.pos];
            eventFire(xaxisLableElement, 'click');
          }
        },
        style: {
          cursor: 'pointer',
          whiteSpace: is_operator && is_fontsize_set ? 'wrap' : 'nowrap',
          fontSize: axisLablesFontSizeSet(userAccessibility)
        }
      }
    },
    ...chartYaxisConfig({ userAccessibility }),
    tooltip: {
      useHTML: true,
      outside: true,
      style: {
        lineHeight: '20px',
        height: "100%",
        whiteSpace: 'nowrap' ,
        width: '250px',
        minWidth:'250px',
        padding:'20px',
      },
      formatter: function () {
        let result;
        let commonTooltip, co2Emmision;
        co2Emmision = this.point.co2_emission ? `<div class="flex-c"><span class=" material-symbols-outlined notranslate-outlined tooltip-eco-icon pe-1">eco</span><span class="semi-bold">${roundToTwoDecimal(this.point.co2_emission)}&nbsp</span> kg CO₂</div>` : ''

        if (this.point.start_end_dates) {
          commonTooltip = `<div class="txt-10">${
            this.point.name
          }</div><div class="flex-c"><span class="material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><span><span  class="semi-bold">${roundToTwoDecimal(
            this.y
          )}</span> kWh</div>${co2Emmision}<div style="font-size:11px">${this.point.start_end_dates[0]} &#8212; ${
            this.point.start_end_dates[1]
          }</div>`;
        } else {
          commonTooltip = ` <div class="txt-10">${
            this.point.name
          }</div><div class="flex-c"><span class="material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><span class="semi-bold">${roundToTwoDecimal(this.y)}</span> kWh ${co2Emmision}</div>`;
        }
        let equipments = `<span class="txt-11" >${
          this.point.number_of_equipments && this.point.number_of_equipments
        } equipment</span>`;
        let incomplete =
          this.point.incomplete_flag && this.point.incomplete_flag
            ? `<span style="color:#C4C4C4;">\u25CF</span><span class=" txt-10"> Incomplete period </span>`
            : '';

        if (this.point.number_of_equipments >= 1) {
          result = `${commonTooltip}${equipments}${incomplete}`;
        } else {
          result = `${commonTooltip}${incomplete}`;
        }
        return result;
      },
      positioner: function(labelWidth, labelHeight, point) {
        var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
      }
    },
    series: [
      {
        data: chartApiData && chartApiData.yaxis_details,
        name: 'Process energy use',
        type: 'column',
        layerParams:{
          process_id:process_id,
          plant_id:plant_id,
          equipment_id:equipment_id,
        },
        tooltip: {
          valueSuffix: ' kWh',
          valueDecimals: 0
        }
      }
    ]
  };
  return config;
}

export default processEnergyUseChartConfig;
