import { useRef, useCallback, useContext } from 'react';
import Highcharts from 'highcharts';
import { useDispatch, useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import { useLocation } from 'react-router-dom';
import { setEquipmentsFilter } from '../../../redux/filterequipments/filterequipments.action';
import { setAlert } from '../../../redux/alerts/alert.action';
import DateRangePicker from '../../../common/datetimepicker/daterangepicker';
import DownloadChartContainer from '../../../common/downloadchart/containers/downloadchart.container';
import ChartExpandContainer from '../../../common/chartexpand/containers/chartexpand.container';
import ChartSettingsContainer from '../../../common/chartsettings/containers/chartsettings.container';
import ChartTopFilterContainer from '../../../common/charttopfilter/containers/charttopfilter.container';
import ComparisonFilterContainer from '../../../common/comparisonfilter/containers/comparisonfilter.container';
import InstantanousPowerTotalKwhComponent from '../../../common/commonchartcomponents/instantanous.power.total.kwh.component';
import { setFilterButtonClick } from '../../../redux/filterbutton/filterbutton';
import { context as FilterContext } from '../../../context/filter.context';
import InstantanousPowerPeakKwhComponent from '../../../common/commonchartcomponents/instantanous.power.peak.kwh';
import TotalInstantaniousPowerTotalValue from '../../../common/commonchartcomponents/total.energy.kwh';
// HighchartsBoost(Highcharts);

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
// import HighchartsBoost from "highcharts/modules/boost";

export default function ComponentLayoutComponent({
  chartData,
  jsChartCallback,
  expandWindow,
  minimizeWindow,
  chartId,
  shouldExpand,
  hasFilter,
  hasAlarm,
  charRef,
  graphFilter,
  setGraphFilter,
  graphFilterObject,
  chartApiData,
  hasSettings,
  selectEquipments,
  filterByDate,
  filterByDateCheck,
  setFilterByDateCheck,
  dateRange,
  setDateRange,
  comparisonView,
  comparisonViewCheck,
  setComparisonViewCheck,
  daySummaryDrilldownDate,
  unsetInnerDrilldown,
  togglePriceCheck,
  setTogglePriceCheck,
  graphFilterObjectEnergyPrice,
  totalKwh,
  props,
  graphInfo,
  isPeakVisible=false,
  peakvalue,
  totalEnergyUse,
  totalEnergyUseVisible,
  toggleHumidity,
  setToggleHumidity,
  hasHumidityToggle,
 
}) {
  const chartContainerRef = useRef();
  const location = useLocation();
  const set_equipment_filter = useSelector((state) => state.equipmentFilter.set_equipment_filter);
  const filter_button_click = useSelector((state) => state.filterButtonClick.filter_button_click);
  const dispatch = useDispatch();
  var fetchFilterChart = useContext(FilterContext).fetchFilterChart;

  const onAlertClick = () => {
    const process_id = new URLSearchParams(location.search).get('process_id');
    const sub_process_id = new URLSearchParams(location.search).get('sub_process_id');
    const equipment_id = new URLSearchParams(location.search).get('equipment_id');
    let click_combination_id = `${process_id}_${sub_process_id}_${equipment_id}`;
    dispatch(setAlert(click_combination_id));
  };
  const onWindowChange = useCallback(() => {
    !shouldExpand ? expandWindow(chartId) : minimizeWindow();
  }, [shouldExpand, expandWindow, minimizeWindow, chartId]);

  const onSetFilterEquipments = ({
    equipmet_list_api_url,
    equipmet_list_params,
    drilldown_level,
    equipment_update_params
  }) => {
    dispatch(setFilterButtonClick(!filter_button_click));

    console.log('clicked home page filter', set_equipment_filter);
    dispatch(
      setEquipmentsFilter({
        equipmet_list_api_url: equipmet_list_api_url,
        params: equipmet_list_params,
        equipment_update_params: equipment_update_params,
        drilldown_level: drilldown_level,
        filter_update: false
      })
    );
    fetchFilterChart({
      equipmet_list_api_url: equipmet_list_api_url,
      params: equipmet_list_params,
      equipMachinement_update_params: equipment_update_params,
      drilldown_level: drilldown_level,
      filter_update: false
    });
  };
  // useEffect(()=>{
  //   isBooster&& HighchartsBoost(Highcharts);
  // },[isBooster])

  
  return (
    <div className= { props?.four_layout ? "home-chart-layout machine-level-page four-layout"  : "home-chart-layout machine-level-page two-layout"}>
      <div
        className={
          comparisonViewCheck
            ? 'flex-btw top-functions comparison-view-top'
            : 'flex-btw top-functions'
        }>
        <div className="flex-c">
          {hasFilter && !comparisonViewCheck ? (
            <ChartTopFilterContainer
              graphFilterObject={
                togglePriceCheck ? graphFilterObjectEnergyPrice : graphFilterObject
              }
              graphFilter={graphFilter}
              setGraphFilter={setGraphFilter}
            />
          ) : (
            ''
          )}
          <label className="flex-c">
            {filterByDate && !comparisonViewCheck ? (
              <i
                onClick={() => {
                  setFilterByDateCheck((prev) => !prev);
                }}
                className={
                  filterByDateCheck
                    ? 'calendar-icon material-icons notranslate graphoptionicons material-icon-grey-active'
                    : 'calendar-icon material-icons notranslate graphoptionicons'
                }>
                calendar_month_mutlined_icon
              </i>
            ) : (
              ''
            )}
            {filterByDateCheck && !comparisonViewCheck ? (
              <div className="filter_by_date--inputs">
                <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} graphInfo={graphInfo} />
              </div>
            ) : (
              ''
            )}
          </label>
          {comparisonViewCheck ? <ComparisonFilterContainer /> : ''}
        </div>
        <div className="flex-c top-functions-right">
          {hasAlarm ? (
            <i className=" material-icons notranslate graphoptionicons" onClick={onAlertClick}>
              notifications
            </i>
          ) : (
            ''
          )}
 {selectEquipments && selectEquipments.equipment_length > 1 ? (
            <>
              <span
                className={
                  selectEquipments.is_equipment_filter_enabled
                    ? 'equipment-filter active'
                    : 'equipment-filter'
                }
                onClick={() =>
                  onSetFilterEquipments({
                    equipmet_list_api_url: selectEquipments.equipmet_list_api_url,
                    equipmet_list_params: selectEquipments.equipmet_list_params,
                    drilldown_level: selectEquipments.drilldown_level,
                    equipment_update_params: selectEquipments.equipment_update_params
                  })
                }>
                Filter
              </span>
            </>
          ) : (
            ''
          )}
          <DownloadChartContainer charRef={charRef} />
          <ChartExpandContainer
            shouldExpand={shouldExpand}
            onWindowChange={onWindowChange}
            charRef={charRef}
            chartContainerRef={chartContainerRef}
          />
          {hasSettings ? (
            <ChartSettingsContainer
              chartId={chartId}
              filterByDateCheck={filterByDateCheck}
              setFilterByDateCheck={setFilterByDateCheck}
              filterByDate={filterByDate}
              comparisonView={comparisonView}
              comparisonViewCheck={comparisonViewCheck}
              setComparisonViewCheck={setComparisonViewCheck}
              togglePriceCheck={togglePriceCheck}
              setTogglePriceCheck={setTogglePriceCheck}
              toggleHumidity={toggleHumidity}
              setToggleHumidity={setToggleHumidity}
              hasHumidityToggle={hasHumidityToggle}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="highcharts-wrp" ref={chartContainerRef}>
        {daySummaryDrilldownDate && (
          <button
            className="grey-lbtn inner-drilldown-back"
            onClick={() => {
              unsetInnerDrilldown();
            }}>
            Back
          </button>
        )}
        <HighchartsReact
          ref={charRef}
          callback={jsChartCallback}
          highcharts={Highcharts}
          options={chartData}
          containerProps={{ style: { height: '100%' } }}
        />
      </div>
      {
        totalEnergyUseVisible &&  totalEnergyUse!==0 && <TotalInstantaniousPowerTotalValue total={totalEnergyUse} />
      }
      {
        isPeakVisible && <InstantanousPowerPeakKwhComponent peak={peakvalue?.value} time={peakvalue?.time}/>
      }
      {chartApiData.equipment_energy_usage && (
        <InstantanousPowerTotalKwhComponent totalKwh={totalKwh} emissionFactor ={chartApiData.emission_factor} />
      )}
    </div>
  );
}
