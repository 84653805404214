export const getLayerId=({component_id,equipment_id,process_id,plant_id})=>{
    if(component_id){
      return component_id
    }
    else if(equipment_id){
      return equipment_id
    }
    else if(process_id){
      return process_id
    }
    else if(plant_id){
      return plant_id
    }
    else{
      return null

    }
  }
  export const getLayerType=({component_id,equipment_id,process_id,plant_id})=>{
    if(component_id){
      return'component'
    }
    else if(equipment_id){
      return 'equipment'
    }
    else if(process_id){
      return 'process'
    }
    else if(plant_id){
      return 'plant'
    }
    else{
      return 'company'

    }
  }
